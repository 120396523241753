var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-6 col-sm-6 col-md-6 col-lg-6"},[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("Pusat")])]),_c('a-table',{attrs:{"scroll":{ y: 400 },"columns":_vm.columns,"dataSource":_vm.data.pusat,"size":"small","pagination":false},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                  .toString()
                  .split(
                    new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                  )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_c('span',[_vm._v(" "+_vm._s(column.dataIndex === "pusat" || column.dataIndex === "cabang" || column.dataIndex === "selisih" ? _vm.curencyFormat(text) : column.dataIndex === "update_pusat" || column.dataIndex === "update_cabang" ? _vm.dateFormat(text) : text)+" ")])]]}},{key:"trueorfalse",fn:function(text){return [_c('a-tag',{attrs:{"type":"red"}},[_vm._v(_vm._s(text))])]}},{key:"periode",fn:function(text){return [_c('label',[_vm._v(_vm._s(_vm.moment(text, "YYYY-MM-DD").format("DD-MM-YYYY")))])]}}])})],2)],1),_c('div',{staticClass:"col-xs-6 col-sm-6 col-md-6 col-lg-6"},[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("Cabang")])]),_c('a-table',{attrs:{"scroll":{ y: 400 },"columns":_vm.columns,"dataSource":_vm.data.cabang,"size":"small","pagination":false},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                  .toString()
                  .split(
                    new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                  )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_c('span',[_vm._v(" "+_vm._s(column.dataIndex === "pusat" || column.dataIndex === "cabang" || column.dataIndex === "selisih" ? _vm.curencyFormat(text) : column.dataIndex === "update_pusat" || column.dataIndex === "update_cabang" ? _vm.dateFormat(text) : text)+" ")])]]}},{key:"trueorfalse",fn:function(text){return [_c('a-tag',{attrs:{"type":"red"}},[_vm._v(_vm._s(text))])]}},{key:"periode",fn:function(text){return [_c('label',[_vm._v(_vm._s(_vm.moment(text, "YYYY-MM-DD").format("DD-MM-YYYY")))])]}}])})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }