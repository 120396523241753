<template>
  <div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card>
          <template slot="title">
            <strong>Pusat</strong>
          </template>
          <a-table
            :scroll="{ y: 400 }"
            :columns="columns"
            :dataSource="data.pusat"
            size="small"
            :pagination="false"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <span>
                  {{
                    column.dataIndex === "pusat" ||
                    column.dataIndex === "cabang" ||
                    column.dataIndex === "selisih"
                      ? curencyFormat(text)
                      : column.dataIndex === "update_pusat" ||
                        column.dataIndex === "update_cabang"
                      ? dateFormat(text)
                      : text
                  }}
                </span>
              </template>
            </template>
            <template slot="trueorfalse" slot-scope="text">
              <a-tag type="red">{{ text }}</a-tag>
            </template>
            <template slot="periode" slot-scope="text">
              <label>{{
                moment(text, "YYYY-MM-DD").format("DD-MM-YYYY")
              }}</label>
            </template>
          </a-table>
        </a-card>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card>
          <template slot="title">
            <strong>Cabang</strong>
          </template>
          <a-table
            :scroll="{ y: 400 }"
            :columns="columns"
            :dataSource="data.cabang"
            size="small"
            :pagination="false"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <span>
                  {{
                    column.dataIndex === "pusat" ||
                    column.dataIndex === "cabang" ||
                    column.dataIndex === "selisih"
                      ? curencyFormat(text)
                      : column.dataIndex === "update_pusat" ||
                        column.dataIndex === "update_cabang"
                      ? dateFormat(text)
                      : text
                  }}
                </span>
              </template>
            </template>
            <template slot="trueorfalse" slot-scope="text">
              <a-tag type="red">{{ text }}</a-tag>
            </template>
            <template slot="periode" slot-scope="text">
              <label>{{
                moment(text, "YYYY-MM-DD").format("DD-MM-YYYY")
              }}</label>
            </template>
          </a-table>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  created() {
    this.getAllData()
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      columns: [
        {
          title: 'Kode',
          width: 70,
          dataIndex: 'kode',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Pusat',
          dataIndex: 'pusat',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          sorter: (a, b) => a.pusat - b.pusat,
          onFilter: (value, record) =>
            record.pusat.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Update Pusat',
          dataIndex: 'update_pusat',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.update_pusat
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Cabang',
          dataIndex: 'cabang',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          sorter: (a, b) => a.cabang - b.cabang,
          onFilter: (value, record) =>
            record.cabang
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Update Cabang',
          dataIndex: 'update_cabang',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.update_cabang
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Selisih',
          dataIndex: 'selisih',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          sorter: (a, b) => a.selisih - b.selisih,
          onFilter: (value, record) =>
            record.selisih
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      data: {
        pusat: [],
        cabang: [],
      },
      componentKey: 0,
    }
  },
  methods: {
    moment,
    async getAllData() {
      var res = await lou.customUrlGet('sync')
      if (res) {
        this.data.pusat = res.data.pusat
        this.data.cabang = res.data.cabang
      }
    },
    curencyFormat(val) {
      // console.log('val', val)
      var nval = val !== '' && val !== null && val !== 0 ? lou.curency(val) : 0
      return nval
    },
    dateFormat(val) {
      // console.log('val', val)
      var rstring = val.replaceAll('T', ' ')
      var nval = val !== '' && val !== null ? moment(rstring, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss') : '-'
      return nval
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
